[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL13;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL14;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL15;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL16;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL9}
a:hover {color: $COL10}
/* menu panel:2 */
.MES2 {
background-color: $COL3;
color: $COL4;
padding: 10px;

 }
.MES2 {
background-color: $COL3;
color: $COL4;
padding: 10px;

h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: $COL5;
 }
h1.MEC2 { font-size: 22.5px; }
h2.MEC2 { font-size: 19.8px; }
h3.MEC2 { font-size: 18px; }
h4.MEC2 { font-size: 16.2px; }
h5.MEC2 { font-size: 15.3px; }
h6.MEC2 { font-size: 14.4px; }
 }
cite.MEC2{
color: $COL4;
}
/* Banner:3 */
.MES3 {
background-color: $COL1;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://williamblake.com.au/img/30/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: contain;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL1;
@media #{$medium-up} {
padding: 0;

}
 }
.MES3 {
background-color: $COL1;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://williamblake.com.au/img/30/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: contain;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL1;
@media #{$medium-up} {
padding: 0;

}
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL5;
 }
h1.MEC3 { font-size: 10px; }
h1.MEC3 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC3 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC3 { font-size: 8.8px; }
h2.MEC3 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC3 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC3 { font-size: 8px; }
h3.MEC3 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC3 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC3 { font-size: 7.2px; }
h4.MEC3 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC3 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC3 { font-size: 6.8px; }
h5.MEC3 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC3 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC3 { font-size: 6.4px; }
h6.MEC3 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC3 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC3{
color: $COL1;
}
/* Mid Blue:4 */
.MES4 {
background-color: $COL6;
color: $COL1;
padding: 15px 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
 }
.MES4 {
background-color: $COL6;
color: $COL1;
padding: 15px 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL1;
 }
h1.MEC4 { font-size: 10px; }
h1.MEC4 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC4 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC4 { font-size: 8.8px; }
h2.MEC4 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC4 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC4 { font-size: 8px; }
h3.MEC4 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC4 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC4 { font-size: 7.2px; }
h4.MEC4 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC4 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC4 { font-size: 6.8px; }
h5.MEC4 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC4 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 6.4px; }
h6.MEC4 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC4 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC4{
color: $COL1;
}
/* Top grey panel:5 */
.MES5 {
background-color: $COL3;
color: $COL4;
padding: 0 10px;

 }
.MES5 {
background-color: $COL3;
color: $COL4;
padding: 0 10px;

h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL5;
 }
h1.MEC5 { font-size: 22.5px; }
h2.MEC5 { font-size: 19.8px; }
h3.MEC5 { font-size: 18px; }
h4.MEC5 { font-size: 16.2px; }
h5.MEC5 { font-size: 15.3px; }
h6.MEC5 { font-size: 14.4px; }
 }
cite.MEC5{
color: $COL4;
}
/* Blue:6 */
.MES6 {
background-color: $COL7;
color: $COL1;
padding: 15px 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
 }
.MES6 {
background-color: $COL7;
color: $COL1;
padding: 15px 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL1;
 }
h1.MEC6 { font-size: 10px; }
h1.MEC6 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC6 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC6 { font-size: 8.8px; }
h2.MEC6 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC6 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC6 { font-size: 8px; }
h3.MEC6 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC6 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC6 { font-size: 7.2px; }
h4.MEC6 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC6 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC6 { font-size: 6.8px; }
h5.MEC6 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC6 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC6 { font-size: 6.4px; }
h6.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC6 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC6{
color: $COL1;
}
/* Content Panel:7 */
.MES7 {
background-color: $COL1;
color: $COL4;
padding: 0 10px;

@media #{$medium-up} {
padding: 0 20px;

}
 }
.MES7 {
background-color: $COL1;
color: $COL4;
padding: 0 10px;

@media #{$medium-up} {
padding: 0 20px;

}
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL5;
 }
h1.MEC7 { font-size: 10px; }
h1.MEC7 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC7 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC7 { font-size: 8.8px; }
h2.MEC7 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC7 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC7 { font-size: 8px; }
h3.MEC7 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC7 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC7 { font-size: 7.2px; }
h4.MEC7 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC7 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC7 { font-size: 6.8px; }
h5.MEC7 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC7 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC7 { font-size: 6.4px; }
h6.MEC7 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC7 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC7{
color: $COL4;
}
/* Treatment Menu:9 */
nav.me-Menu.MES9 {
& .menu-item.MEC9, & .menu-item.MEC9 > div.MEC9{ & > a.MEC9{color: $COL1;
}
  }
&.horizontal > .menu-item.MEC9 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC9 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC9 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC9 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Light:10 */
.MES10 {
background-color: $COL11;
 }
/* Light:11 */
.MES11 {
background-color: $COL11;
 }
.MES11 {
background-color: $COL11;
 }
/* Accordion:12 */
details.MES12 {
color: $COL12;
& > article { color: $COL12;
 }
& > summary { color: $COL12;
 }
 }
/* Primary:13 */
.MES13 {
background-color: $COL17;
color: $COL18;
 }
/* Primary:14 */
.MES14 {
background-color: $COL17;
color: $COL18;
 }
.MES14 {
background-color: $COL17;
color: $COL18;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL18;
 }
 }
cite.MEC14{
color: $COL18;
}
/* FAQ:15 */
details.MES15 {
& > summary{border-width: 0 0 1px 0;
border-style:dashed;
border-color: $COL6;
}& > summary { color: $COL17;
 }
 }
/* Top Menu:16 */
nav.me-Menu.MES16 {
& .menu-item.MEC16, & .menu-item.MEC16 > div.MEC16{ & > a.MEC16{color: $COL17;
}
  }
&.horizontal > .menu-item.MEC16 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC16 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC16 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC16 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Button:17 */
.MES17 {
background-color: $COL19;
 }
