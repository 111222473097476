$COL1: #ffffff; //white
$COLFLAT1: #ffffff; //white
$COL2: #000000; //black
$COLFLAT2: #000000; //black
$COL3: #f9f9f9; //light grey
$COLFLAT3: #f9f9f9; //light grey
$COL4: #555555; //Font
$COLFLAT4: #555555; //Font
$COL5: #0b8ab9; //primary
$COLFLAT5: #0b8ab9; //primary
$COL6: #54a7c4; //Mid Blue
$COLFLAT6: #54a7c4; //Mid Blue
$COL7: #188bbb; //Blue
$COLFLAT7: #188bbb; //Blue
$COL8: rgba(0,0,0,0); //
$COLFLAT8: #808080; //
$COL9: #767676; //
$COLFLAT9: #767676; //
$COL10: #0b8ab9; //
$COLFLAT10: #0b8ab9; //
$COL11: #f2f2f2; //
$COLFLAT11: #f2f2f2; //
$COL12: #0b8ab9; //
$COLFLAT12: #0b8ab9; //
$COL13: #0b8ab9; //
$COLFLAT13: #0b8ab9; //
$COL14: #0b8ab9; //
$COLFLAT14: #0b8ab9; //
$COL15: #0b8ab9; //
$COLFLAT15: #0b8ab9; //
$COL16: #0b8ab9; //
$COLFLAT16: #0b8ab9; //
$COL17: #0b8ab9; //
$COLFLAT17: #0b8ab9; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #116183; //Button
$COLFLAT19: #116183; //Button
/* WARNING: Unable to find output for 1:base style (type=base)*/
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
